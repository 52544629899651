#blog {
  z-index: 1;
  background-color: white;

  .container {

    .row {

      .col {

        h1 {
          text-align: center;
        }
        h2 {
          text-align: center;
          padding-bottom: 50px;
        }

        .blog_copy_holder{
          //padding-bottom: 100px;


          .back_link {
            padding-top: 30px;
          }

          h2 {
            padding-bottom: 50px;
            font-size: 25px;
            line-height: 25px;
            padding-bottom: 30px;
            text-align: left;

            @include media-breakpoint-up(sm) {
              font-size: 22px;
              line-height: 22px;
              padding-bottom: 30px;
            }
            @include media-breakpoint-up(md) {
              font-size: 22px;
              line-height: 22px;
              padding-bottom: 30px;
            }
            @include media-breakpoint-up(lg) {
              font-size: 28px;
              line-height: 28px;
              padding-bottom: 40px;
            }
            @include media-breakpoint-up(xl) {
              font-size: 34px;
              line-height: 34px;
              padding-bottom: 50px;
            }

          }
          h3 {

            font-size: 18px;
            line-height: 18px;


            @include media-breakpoint-up(sm) {
              font-size: 18px;
              line-height: 18px;
            }
            @include media-breakpoint-up(md) {
              font-size: 18px;
              line-height: 18px;
            }
            @include media-breakpoint-up(lg) {
              font-size: 21px;
              line-height: 21px;
            }
            @include media-breakpoint-up(xl) {
              font-size: 24px;
              line-height: 24px;
            }

          }
          h2, h3 {
            color: $blue;
          }
          p, h4 {
            //color: $dark_grey;
          }
          a {
            color: $blue;

            &:hover {
              text-decoration: underline;
            }
          }

          img {
            width: 100%;
          }

          .blog_teaser_holder {
            text-align: center;
            margin: 0px;
            //border: 1px solid #999;
            //background-color: #f9f9f9;

            .teaser_copy,
            .teaser_link{
              padding: 20px;

              h2 {
                font-size: 21px;
                line-height: 21px;
                padding-bottom: 21px;
                text-align: center;

                @include media-breakpoint-up(sm) {
                  font-size: 21px;
                  line-height: 21px;
                  padding-bottom: 21px;
                }
                @include media-breakpoint-up(md) {
                  font-size: 19px;
                  line-height: 19px;
                  padding-bottom: 19px;
                }
                @include media-breakpoint-up(lg) {
                  font-size: 21px;
                  line-height: 21px;
                  padding-bottom: 21px;
                }
                @include media-breakpoint-up(xl) {
                  font-size: 24px;
                  line-height: 24px;
                  padding-bottom: 24px;
                }

              }

              h3 {
                font-size: 18px;
                line-height: 18px;

                @include media-breakpoint-up(sm) {
                  font-size: 18px;
                  line-height: 18px;
                }
                @include media-breakpoint-up(md) {
                  font-size: 16px;
                  line-height: 16px;
                }
                @include media-breakpoint-up(lg) {
                  font-size: 18px;
                  line-height: 18px;
                }
                @include media-breakpoint-up(xl) {
                  font-size: 18px;
                  line-height: 18px;
                }
              }

              p,a {
                font-size: 15px;
                line-height: 22px;
                //padding-bottom: 20px;

                @include media-breakpoint-up(sm) {
                  font-size: 15px;
                  line-height: 22px;
                  padding-bottom: 20px;
                }
                @include media-breakpoint-up(md) {
                  font-size: 14px;
                  line-height: 20px;
                  padding-bottom: 20px;
                }
                @include media-breakpoint-up(lg) {
                  font-size: 15px;
                  line-height: 22px;
                  padding-bottom: 24px;
                }
                @include media-breakpoint-up(xl) {
                  font-size: 16px;
                  line-height: 24px;
                  padding-bottom: 24px;
                }
              }

              a {
                padding-top: 40px;
              }
            }
          }
        }
      }
    }

    .row-flex > * {
      padding-right: calc(var(--bs-gutter-x) * .8);
      padding-left: calc(var(--bs-gutter-x) * .8);
      margin-top: var(--bs-gutter-y);
    }

    .row-flex {
      display: flex;
      flex-wrap: wrap;

      .col {
        margin-bottom: 40px;

        .blog_copy_holder {
          //box-shadow: 0 3px 4px rgba(0,0,0,.02);
          background-color: #f9f9f9;
          height: 100%;

          .blog_teaser_holder {
            position: relative;
            padding-bottom: 30px;
            height: 100%;

            .teaser_copy {
              padding-bottom: 60px;

            }
            .teaser_link {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: 15px;
            }
          }
        }
      }
    }
  }
}