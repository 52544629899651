header {
  background: $light-grey;
}

.header_bubbles {
  z-index: 999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  transform-origin: right bottom;

  .header_bubble {
    cursor: pointer;
    position: absolute;
    transition: width .35s;


    // 15
    &.header_bubble_phone {
      bottom: 15px; // 1
      right: 45px; // 3
      width: 60px; // 4
    }
    &.header_bubble_mail{
      bottom: 90px; // 6
      right: 15px; // 1
      width: 45px; // 3
    }
    &.header_bubble_news{
      bottom: 90px; // 6
      right: 75px; // 5
      width: 90px; // 6
    }

    // 15
    @include media-breakpoint-up(sm) {

      &.header_bubble_phone {
        bottom: 15px; // 1
        right: 45px; // 3
        width: 60px; // 4
      }
      &.header_bubble_mail{
        bottom: 90px; // 6
        right: 15px; // 1
        width: 45px; // 3
      }
      &.header_bubble_news{
        bottom: 90px; // 6
        right: 75px; // 5
        width: 90px; // 6
      }
    }

    // 20
    @include media-breakpoint-up(md) {

      &.header_bubble_phone {
        bottom: 20px; // 1
        right: 60px; // 3
        width: 80px; // 4
      }
      &.header_bubble_mail{
        bottom: 100px; // 6
        right: 20px; // 1
        width: 60px; // 3
      }
      &.header_bubble_news{
        bottom: 120px; // 6
        right: 100px; // 5
        width: 120px; // 6
      }
    }

    // 25
    @include media-breakpoint-up(lg) {

      &.header_bubble_phone {
        bottom: 25px; // 1
        right: 75px; // 3
        width: 100px; // 4
      }
      &.header_bubble_mail{
        bottom: 150px; // 6
        right: 25px; // 1
        width: 75px; // 3
      }
      &.header_bubble_news{
        bottom: 150px; // 6
        right: 125px; // 5
        width: 150px; // 6
      }

      &.header_bubble_phone {
        bottom: 20px; // 1
        right: 60px; // 3
        width: 80px; // 4
      }
      &.header_bubble_mail{
        bottom: 100px; // 6
        right: 20px; // 1
        width: 60px; // 3
      }
      &.header_bubble_news{
        bottom: 120px; // 6
        right: 100px; // 5
        width: 120px; // 6
      }
    }

    img, svg {
      transition: 1s;
      transform: scale(.5);
      opacity: 0;
      width: 100%;
    }

    &.show {

      img, svg{
        opacity: 1;
        transform: scale(1);
      }
    }

    &.hide {
      opacity: 0;
    }
    &.display_none {
      display: none !important;
    }

    &:hover {

      img {
        transform: scale(1.2);
      }
    }
  }

  &.small {
    transform: scale(.7);
  }
}

.section_header{
  background-color: black;
  overflow: hidden;
  height: 100%;
  min-height: 100%;

  &.section_header_sub {
    height: 50%;
    min-height: 50%;

    @include media-breakpoint-up(md) {
      height: 65%;
      min-height: 65%;
    }
  }

  .header_video_container,
  .header_image_container{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0s;

    background-size: cover;
    background-position: center;

    -ms-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
    transform: scale(1.4);

    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;

    &.show {
      transition: 2500ms;
      -ms-transform: scale(1);
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      transform:  scale(1);
    }

    .home_video {
      object-fit: cover;
      z-index: 100;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: 100vh;
      z-index: 0;
      //transition: 2500ms;

      -ms-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);

      .vjs-tech {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .vjs-tech {
        min-width: 100% !important;
        min-height: 100% !important;
      }
    }

    .header_image {
      z-index: 100;
      width: 100%;
      height: 100%;

      z-index: 0;

      background-size: cover;
      background-position: top center;

      img {
        display: none;
      }
    }

    .header_content {
      position: relative;
      z-index: 1;
      min-height: 100%;
      height: 100%;
      width: 100%;

      .container {
        height: 100%;

        .row {
          height: 100%;

          .col  {
            height: 100%;

            .header_headline {
              opacity: 1;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              max-width: 700px;
              max-width: 1000px;
              width: 90%;

              @include media-breakpoint-up(lg) {
                transform: translate(-60%, -50%);
                width: 60%;
              }

              img {
                width: 100%;
              }

              &.show {
                transition: opacity 1s;
                opacity: 1;
              }

              .header_arrow {
                cursor: pointer;
                position: absolute;
                bottom: 0px;
                width: 8%;
                left: 0px;
                -webkit-animation: animate_arrow_down 5s infinite;
                animation: animate_arrow_down 5s infinite;
              }
            }
          }
        }
      }
    }
  }


  .vjs-poster {
    background-size: cover;
  }

  .vjs-loading-spinner{display:none!important;}
}

@-webkit-keyframes animate_arrow_down {
  0% {
    transform:  translate(0, -20px);
    opacity: 0;
  }
  10% {
    -webkit-transform:  translate(0, 0);
    opacity: 1;
  }
  90% {
    -webkit-transform:  translate(0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_down {
  0% {
    transform:  translate(0, -20px);
    opacity: 0;
  }
  10% {
    transform:  translate(0, 0);
    opacity: 1;
  }
  90% {
    transform:  translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(0px, 20px);
    opacity: 0;
  }
}