#green {
  z-index: 1;
  background-color: white;
  overflow-x: hidden;

  .container {

    padding-top: 50px;

    .row {

      .col {

      }

      .col_green {
        position: relative;
        border: 2px solid $green !important;
        padding: 50px;
        padding-top: 100px;
        padding: 8.3%;

        h1 {
          position: absolute;
          top: -60px;
          right: -8.3%;
          padding: 4%;
          padding: 8.3%  4%;
          padding-bottom: 0;
          height: auto;
          margin-bottom: 0px !important;
          background-color: white;
          display: flex;
          color: $green;

          .green_bobble {
            width: 52px;
            height: 52px;
            margin-left: 10px;
            margin-top: 0px;

            @include media-breakpoint-up(sm) {
              width: 52px;
              height: 52px;
            }
            @include media-breakpoint-up(md) {
              width: 64px;
              height: 64px;
            }
            @include media-breakpoint-up(lg) {
              width: 80px;
              height: 80px;
              margin-top: -10px;
            }
            @include media-breakpoint-up(xl) {
              width: 100px;
              height: 100px;
              margin-top: -10px;
            }
          }
        }

        @include media-breakpoint-up(sm) {

          h1 {
            top: -75px;
            padding: 8.3%;
          }
        }
        @include media-breakpoint-up(md) {

          h1 {
            top: -90px;
          }
        }
        @include media-breakpoint-up(lg) {

          h1 {
            top: -120px;
          }
        }
        @include media-breakpoint-up(xl) {
          //padding: 8.3%;
          //padding-top: 16.6%;

          h1 {
            top: -140px;
          }
        }
      }
    }
  }
}