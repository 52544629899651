#angebot {
  z-index: 1;
  background-color: white;

  @include media-breakpoint-up(xxl) {
    padding-bottom: 0px;
  }

  .angebot_blue_bg_bubble {
    z-index: 1;
    top: 50px;
    left: -50px;

    img, svg {
      transform: translateX(0);
    }
  }

  .container {
    position: relative;
    will-change: transform;
    z-index: 2;

    .row{
      display: flex;
      align-items: center;

      .col {

       ul {
         padding-bottom: 10px;

         li {
           margin: 0;
           padding: 10px 0 10px 30px;
           list-style: none;
           background-image: url("./images/svg/bg_blue_bubbles.svg");
           background-repeat: no-repeat;
           background-position: left center;
           background-size: 20px;
         }
       }

        a {
          font-family: "Gilroy-Regular";
          color: $blue;
          display: block;
          text-align: right;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}