#jobs {
  z-index: 1;
  background-color: white;

  .jobs_blue_bg_bubble {
    top: 50px;
    left: -50px;

    img, svg {
      transform: translateX(0);
    }
  }


  .container {
    position: relative;
    z-index: 2;
    padding-bottom: 82px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 102px;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 128px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 160px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 200px;
    }

    .row {

      .col {

        .button_holder {
          text-align: left;
        }
      }
    }
  }
}