.modal-dialog {

  .modal-content{
    background-color: transparent;

    .modal-body {
      position:relative;
      padding: 60px 0;
      padding: 0px 0;
      background-color: rgba(000,000,000,.5);
      display: flex;
      justify-content: center;
      align-items: center;

      .modal_button,
      .modal_button_close {
        cursor: pointer;
        z-index: 100;
        position: fixed;
        transform: translateX(-50%);
        font-size: 40px;
        color: white;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;

        .modal_icon_bg {
          fill: transparent;
        }

        img, svg {
          vertical-align: top;
          width: 100%;
          transition: 0.5s;
        }
      }

      .modal_button_close {
        top: 10px;
        left: 50%;

        &:hover {

          img, svg {
            transform: rotate(90deg);
          }
        }
      }
      .modal_button_next {
        top: auto;
        bottom: 10px;
        left: calc(50% + 28px);

        .modal_icon_path {
          transition: 0.5s;
        }

        &:hover {

          .modal_icon_path {
            transform: translateX(50px);
          }
        }
      }
      .modal_button_prev {
        top: auto;
        bottom: 10px;
        left: calc(50% - 28px);

        .modal_icon_path {
          transition: 0.5s;
        }

        &:hover {

          .modal_icon_path {
            transform: translateX(50px);
          }
        }
      }

      .video-responsive{
        overflow:hidden;
        position:relative;
        height:0;
        width: calc(100% - 100px);
        height: calc(100% - 100px);
        margin: 50px;
      }
      .video-responsive iframe{
       /* left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;*/

        //padding-bottom:56.25%;
      }

      .container,
      .text_holder {
        position: relative;
        background-color: white;
        width: 90%;
        height: auto;
        padding: 10% 5%;
        padding: 40px;
        margin: auto;

        .modal_text_button_close {
          z-index: 100;
          cursor: pointer;
          position: absolute;
          right:-5px;
          top: 10px;
          font-size: 40px;
          color: white;
          width: 40px;
          height: 40px;
          padding: 0;
          margin: 0;
          transform-origin: center center;

          .modal_icon_path {
            fill: $blue;
          }
          .modal_icon_bg {
            fill: transparent;
          }

          img, svg {
            vertical-align: top;
            width: 100%;
            transition: 0.5s;
          }

          &:hover {
            img, svg {
              transform: rotate(90deg);
            }
          }
        }

        h1 {
          color: $blue;
          margin-left: 0px !important;
        }

        h2 {
          color: $blue;
          padding-top: 20px;
        }
        p {
          color: $dark_grey;
        }

        .row {

          .col {
            display: flex;
            justify-content: center;
            align-items: center;

            .modal_image,
            .modal_image_mobile {
              position: relative;
              width: 100%;

              .modal_image-text_bottom {
                padding-top: 5px;
                //padding-bottom: 40px;
              }

              .modal_image-text_left {
                position: absolute;
                bottom: 0px;
                left: 0px;
                transform: rotate(-90deg);
                transform-origin: left bottom;
                display: none;

                @include media-breakpoint-up(sm) {
                  display: block;
                }
              }

              img {
                width: 100%;
              }
            }

            .modal_image {
              display: none;
            }

            @include media-breakpoint-up(lg) {

              .modal_image {
                display: block;
              }
              .modal_image_mobile {
                display: none;
              }
            }

            .modal_text {
              padding-left: 0px;

              .modal_headline_top,
              .modal_headline_bottom{
                display: flex;
                width: auto;

                h3 {
                  padding-bottom: 5px;
                }

                .leistungen_item_icon {
                  width: 30px;

                  .circle_blue_big {
                    transition: 0.5s;
                    left: -30px;
                  }
                  .circle_blue_small {
                    transition: 0.5s;
                    transform-origin: 0 0;
                  }

                  img, svg {
                    width: 30px;
                    margin: 0 auto;
                    margin-top: -30px;
                    margin-left: 5px;
                  }
                }
              }
              .modal_headline_bottom {
                padding-bottom: 20px;
              }

              @include media-breakpoint-up(lg) {
                padding-left: 50px;
              }
            }
          }
        }

        h2, p {
          //padding-right: 40px;
        }

        h2,h3 {
          //color: $blue;
        }

        p {
          //color: $middle_grey;
        }
      }
    }
  }
}

slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}