.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: -25px;
  z-index: 1000;
  width: 100%;
  padding: 0px;
  opacity: 0;
  transition: 1000ms;

  .background {
    position: absolute;
    display: none;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: $blue;
    transition: 1000ms;

    &.hide {
      top: -200px;
    }

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .top-nav {
    position: absolute;
    z-index: 10;
    top: 25px;
    right: 90px;
    transition: 1000ms;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      right: 120px;
    }

    .header_icons {
      margin-right: 0px;

      .header_icon {
        cursor: pointer;
        width: 25px;
        display: inline-block;
        vertical-align:top;
        margin: 0 2px;

        @include media-breakpoint-up(sm) {
          width: 30px;
        }

        .socialmedia_icon_white_trans {
          transition: 0.5s;
          fill: white;
        }

        img, svg {
          width: 100%;
        }

        &:hover {

          .socialmedia_icon_white_trans {
            fill: $blue;
          }
        }

        &.over_menu {

          &:hover {

            .socialmedia_icon_white_trans {
              fill: #fce678;
              //opacity: .7;
            }
          }
        }
      }

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-right: 50px;
      }
    }

    .language_switcher {
      display: none;

      a {

        &.over_menu {

          &:hover {
            color: white;
            color: #fce678;
          }
        }
      }

      a, p {
        margin: 0 3px;
      }

      @include media-breakpoint-up(md) {
        vertical-align:top;
        display: inline-block;
      }
    }

    &.show {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  .logo {
    position: fixed;
    z-index: 4;
    transition: opacity 500ms;
    width: 100px;
    max-width: 224px;
    left: 25px;
    top: 25px;
    opacity: 1;
    transform-origin: left top;

    #svg_logo{
      transition: .5s;
      fill: white;
    }

    &.blue {

      #svg_logo{
        fill: $blue;
      }
    }

    &.white {

      #svg_logo{
        fill: white !important;
      }
    }

    @include media-breakpoint-up(sm) {
      width: 120px;
    }
    @include media-breakpoint-up(md) {
      width: 150px;
    }
    @include media-breakpoint-up(lg) {

    }
    @include media-breakpoint-up(xl) {

    }

    img, svg {
      width: 100%;
    }
  }

  #toggle {
    z-index: 10;
    position: fixed;
    top: 20px;
    top: -5px;
    right: 30px;
    height: 27px;
    width: 35px;
    cursor: pointer;
    margin-right: 0px;
    transition: 1000ms;

    @include media-breakpoint-up(md) {
      right: 30px;
    }

    span{
      background: white;
      border: none;
      height: 2px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.35s ease;
      cursor: pointer;
      border-radius: 0px;

      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 80%;
        top: 11px;
      }
      &:nth-of-type(3) {
        width: 60%;
        top: 22px;
      }
    }

    &.active {
      .top {
        transform: translateY(11px) translateX(0) rotate(45deg);
        background: white;
      }
      .middle {
        opacity: 0;
        background: white;
      }
      .bottom {
        transform: translateY(-11px) translateX(0) rotate(-45deg);
        background: white;
      }

      span{

        &:nth-of-type(3) {
          width: 100%;
        }
      }
    }

    &:hover{

      span{
        background: $blue;
      }
    }

    &.over_menu {

      &:hover {
        transform: rotate(90deg);
        //opacity: .7;

        span {
          background: #fce678;
        }
      }
    }

    &.blue {

      span{
        background: $blue;
      }
    }

    &.show {
      top: 25px;
    }
  }

  &.show {
    opacity: 1;
    top: 0;
  }

  &.hide {

    .top-nav {

      &.left {
        top: -200px;
      }
      &.right {
        top: -200px;
      }
    }
  }
}

#mainNav {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  opacity: 1;
  visibility: hidden;

  -webkit-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

  nav {
    background: #2aa0e7;
    position: absolute;
    right: -500px;
    top: 0px;
    transform: translateY(0);
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;

    -webkit-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition: all 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
    -webkit-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    -o-transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */

    @include media-breakpoint-up(md) {
      right: -420px;
      width: 420px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      position: relative;
      padding-left: 0px !important;
      //border: 1px solid red;

      li {
        display: block;
        line-height: 3rem;
        position: relative;
        opacity: 0;
        padding: 15px 0;
        text-align: center;

        .navi_spacer {
          margin: 0 auto;
          width: 100%;
          height: 1px;
          background-color: white;
          opacity: 0;
        }

        a, p {
          font-family: "Gilroy-Regular", serif;
          font-size: 27px;
          line-height: 40px;
          display: block;
          position: relative;
          text-decoration: none;
          overflow: hidden;
          letter-spacing: 5px;
          transition: 300ms;
          padding-left: 5px;
          padding-bottom: 0px;

          &.medium {
            font-size: 25px;
            line-height: 25px;
          }
          &.small {
            font-size: 20px;
            line-height: 20px;
          }

          &:hover,
          &.active {
            //color: $yellow !important;
            text-decoration: underline;
          }
        }

        &.language {

          a,p {
            display: inline-block;
          }

          p {
            font-family: "Gilroy-SemiBold";
          }
        }
      }

      @include media-breakpoint-up(md) {
        margin-left: 20%;

        li {
          text-align: left;
        }
      }
    }
  }

  .language_switcher {
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    text-align: center;

    a,p {
      display: inline-block;
      margin: 0 5px;
    }

    a {

      &:hover {
        color: $yellow !important;
      }
    }

    p {
      cursor: default;
      font-family: "Gilroy-SemiBold";
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    right: 0px;
    z-index: 3;

    nav {
      right: 0px;

      ul {

        .navi_spacer {
          animation: fadeInLeft 0.6s ease forwards;
          opacity: 0;
        }

        li {
          animation: fadeInLeft 0.6s ease forwards;
          opacity: 0;
          animation-delay: .2s;

          // referenzen
          &:nth-of-type(2) {
            animation-delay: .25s;
          }

          // ueber uns
          &:nth-of-type(3) {
            animation-delay: .3s;
          }

          // contact
          &:nth-of-type(4) {
            animation-delay: .35s;
          }

          // spacer
          &:nth-of-type(5) {
            animation-delay: .35s;
          }

          // blog
          &:nth-of-type(6) {
            animation-delay: .45s;
          }

          // faq
          &:nth-of-type(7) {
            animation-delay: .5s;
          }
          // language
          &:nth-of-type(8) {
            animation-delay: .55s;
          }
        }
      }
    }
  }

  &.closing {

    nav {

      ul {

        li {
          opacity: 1;
          animation: fadeOutLeft 0.4s ease forwards;

          // language
          &:nth-of-type(8) {
            animation-delay: 0s;
          }

          // faq
          &:nth-of-type(7) {
            animation-delay: 0.05s;
          }

          // blog
          &:nth-of-type(6) {
            animation-delay: 0.1s;
          }

          // line
          &:nth-of-type(5) {
            animation-delay: 0.15s;
          }

          // contact
          &:nth-of-type(4) {
            animation-delay:  0.2s;
          }

          // // ueber uns
          &:nth-of-type(3) {
            animation-delay: 0.25s;
          }

          // referenzen
          &:nth-of-type(2) {
            animation-delay: 0.3s;
          }

          // leistungen
          &:nth-of-type(1) {
            animation-delay: 0.35s;
          }
        }
      }
    }
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    left: 100px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 100px;
  }
}

@-webkit-keyframes animate_arrow_down {
  0% {
    -webkit-transform:  translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_down {
  0% {
    transform:  translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0px, 20px);
    opacity: 0;
  }
}

@-webkit-keyframes animate_arrow_up {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, -20px);
    opacity: 0;
  }
}
@keyframes animate_arrow_up {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform:translate(0px, -20px);
    opacity: 0;
  }
}
