#podcast {
  z-index: 1;
  background-color: white;
  //padding-bottom: 200px;

  @include media-breakpoint-up(sm) {

  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-up(xl) {

  }

  .container,
  .container-fluid {

    .row {

      .col {
        position: relative;
        display: flex;
        align-items: center;

        .podcast_text_holder {
          padding-right: 0px;

          @include media-breakpoint-up(md) {
            padding-right: 30px;
          }
        }

        a {
          font-family: "Gilroy-Regular";
          color: $blue;
          display: block;
          text-align: left;

          &:hover {
            text-decoration: underline;
          }
        }

        .round_button {
          margin-bottom: 50px;
        }
      }
    }
  }
}