#leistungen {
  z-index: 2;
  background-color: white;

  .leistungen_blue_bg_bubble {
    z-index: 1;
  }

  .container {
    will-change: transform;
    position: relative;
    z-index: 2;

    .row {

      .col {

        .content_image_mask{
          margin-bottom: 40px;
        }

        .container {
          padding-right: 0;
          padding-left: 0;
          padding-top: 50px;

          .row {
            padding-right: 0;
            padding-left: 0;

            .col {
              //flex: 0 0 auto;
              text-align: right;

              .leistungen_item {
                cursor: pointer !important;
                width: 100%;
                width: auto;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .leistungen_item_headline_top,
                .leistungen_item_headline_bottom{
                  display: flex;
                  width: auto;

                  .leistungen_item_icon {
                    width: 30px;

                    .circle_blue_big {
                      transition: 0.5s;
                      left: -30px;
                    }
                    .circle_blue_small {
                      transition: 0.5s;
                      transform-origin: 0 0;
                    }

                    img, svg {
                      width: 30px;
                      margin: 0 auto;
                      margin-top: -30px;
                      margin-left: 5px;
                    }
                  }
                }

                .leistungen_item_link {
                  padding-top: 10px;
                  text-align: left;

                  a, p {
                    transition: .5s;
                    text-decoration: none;
                  }
                }

                &:hover {

                  .leistungen_item_headline_top,
                  .leistungen_item_headline_bottom {

                    h2 {
                      color: $blue;
                    }

                    .leistungen_item_icon {
                      .circle_blue_big {
                        transition: 0.5s;
                        transform: translateY(50%) scale(.5);

                      }
                      .circle_blue_small {
                        transform: translateX(-100%) scale(2);
                      }
                    }
                  }

                  .leistungen_item_link {

                    a,p {
                      color: $blue !important;
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .col_align_bottom {
        display: flex;
        justify-content:center;
        flex-direction: column;
      }
    }
  }
}