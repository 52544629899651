.section {
  position: relative;
  background-color: white;
  padding-top: 33px;
  padding-top: 66px;
  padding-bottom: 33px;

  @include media-breakpoint-up(sm) {
    padding-top: 41px;
    padding-bottom: 41px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include media-breakpoint-up(xxl) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &.section_padding_about {
    padding-bottom: 0px;

    @include media-breakpoint-up(sm) {
      padding-bottom: 0px;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 0px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 128px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 160px;
    }
  }

  .blue_bg_bubble {
   // display: none;
    z-index: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 205px;
    opacity: .04;
    overflow-x: hidden;

    img, svg {
      width: 100%;
      transform: translateX(10%);
    }


    @include media-breakpoint-up(sm) {
      width: 256px;
    }
    @include media-breakpoint-up(md) {
      width: 320px;
    }
    @include media-breakpoint-up(lg) {
      width: 400px;
    }
    @include media-breakpoint-up(xl) {
      width: 500px;
    }
  }

  .container,
  .container-fluid{
    will-change: transform;

    .row {

      .col {

        border: 1px solid red;
        border: 0px solid red !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 1px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }
        @include media-breakpoint-up(xxl) {
          border: 1px solid violet;
        }


        .content_image {
          position: relative;
          width: 100%;
          margin-top: 20px;

          @include media-breakpoint-up(md) {
            margin-top: 0px;
          }

          img {
            width: 100%;
          }

          .content_image_desktop {
            display: none;
          }

          @include media-breakpoint-up(md) {

            .content_image_desktop {
              display: block;
            }
            .content_image_mobile {
              display: none;
            }
          }

          .content_image_text_left {
            z-index: 100;
            position: absolute;
            bottom: 0px;
            left: 0px;
            transform: rotate(-90deg);
            transform-origin: left bottom;
            width: 150%;
          }
        }

        .content_image_mask,
        .content_image_mask_portrait,
        .content_image_mask_landscape{
          position: relative;
          width: 100%;
          padding-top: 100%;
          overflow: hidden;



          &.content_image_mask_portrait {
            padding-top: 150%;
          }
          &.content_image_mask_landscape {
            padding-top: 58%;
          }
          &.content_image_mask_landscape_80 {
            padding-top: 80%;
          }
          &.content_image_mask_landscape_2 {
            padding-top: 100%;
          }

          &.content_image_mask_200 {
            padding-top: 200%;
          }
          &.content_image_mask_120{
            padding-top: 120%;
          }
          &.content_image_mask_100{
            padding-top: 100%;
          }

          .content_image {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 130%;
            width: 100%;
            margin-left: -15%;
            margin-left: 0%;
            transform-origin: center center;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#CookiebotWidget {
  display: none !important;
}

.debug {
  display: none;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100px;
  height: 60px;
  background-color: white;
  padding: 20px;
  text-align: center;
  opacity: .5;
}


//.navbar,
//#header,
//#news,
//#angebot,
//#leistungen,
//#referenzen,
//#kunden,
  //#about,
  //#team,
  //#werte,
  //#fotos,
  //#green,
  //#werbefilm,
  //#contact,
  //#googlemap,
  //#footer,
#test{
  display: none;
}
