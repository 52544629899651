#news {
  z-index: 1;
  background-color: white;

  .news_blue_bg_bubble {}

  .container {

    .row {

      .col {

        .news_copy_holder {

          h2 {
            padding-bottom: 50px;
            font-size: 25px;
            line-height: 25px;
            padding-bottom: 30px;

            @include media-breakpoint-up(sm) {
              font-size: 22px;
              line-height: 22px;
              padding-bottom: 30px;
            }
            @include media-breakpoint-up(md) {
              font-size: 22px;
              line-height: 22px;
              padding-bottom: 30px;
            }
            @include media-breakpoint-up(lg) {
              font-size: 28px;
              line-height: 28px;
              padding-bottom: 40px;
            }
            @include media-breakpoint-up(xl) {
              font-size: 34px;
              line-height: 34px;
              padding-bottom: 50px;
            }

          }
          h2, h3 {
            color: $blue;
          }
          p, h4 {
            color: $dark_grey;
          }

          a {
            color: $blue;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}