#googlemap {
  z-index: 1;
  background-color: white;
  padding-top: 0;
  //padding-bottom: 0;

  .container-fluid {

    .row {

      .col {
        position: relative;
        overflow: hidden;

        .map {
          //position: absolute;
          width: 100% !important;

          img {
            width: 100%;
          }

          @include media-breakpoint-up(md) {
            //padding-bottom: 50%;
          }
        }
      }
    }
  }
}