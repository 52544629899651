.section {

  .container {

    .row {

      .col {

        .content_video_thumb{
          cursor: pointer;
          width: 100%;
          overflow: hidden;

          img{
            transition: 1s;
            width: 100%;
          }

          &:hover {

            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}