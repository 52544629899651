#referenzen {
  z-index: 1;
  background-color: white;

  .referenzen_blue_bg_bubble {
    top: 50px;
    left: -50px;
    z-index: 1;

    img, svg {
      transform: translateX(0);
    }
  }

  .container,
  .container-fluid {
    will-change: transform;
    position: relative;
    z-index: 2;

    .row {

      .col {
        position: relative;

        .referenzen_copy {
          margin-bottom: 10px;
          padding-bottom:0;

          @include media-breakpoint-up(sm) {
            margin-bottom: 13px;
          }
          @include media-breakpoint-up(md) {
            margin-bottom: 16px;
          }
          @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
          }
          @include media-breakpoint-up(xl) {
            margin-bottom: 25px;
          }
        }

        .referenzen_project_filter {
          z-index: 999;
          position: relative;
          margin-bottom: 21px;
          height: 50px;
          //border: 1px solid red;

          @include media-breakpoint-up(sm) {
            margin-bottom: 26px;
          }
          @include media-breakpoint-up(md) {
            margin-bottom: 32px;
          }
          @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
          }
          @include media-breakpoint-up(xl) {
            margin-bottom: 0px;
            margin-top: 60px;
          }

          .referenzen_project_filter_holder {
            z-index: 999;
            position: absolute;
            top: 0px;
            left: 0px;
            text-align: center;
            $timing:.3s;

            .referenzen_dropdown {
              font-family: "Gilroy-Regular", serif;
              min-width: 13em;
              position: relative;
              display: inline-block;
              margin-right: 1em;
              min-height: 2em;
              max-height:2em;
              overflow:hidden;
              top: .5em;
              cursor: pointer;
              text-align: left;
              white-space: nowrap;
              color: #444;
              transition: $timing all ease-in-out;
              outline: none;
              border: .06em solid transparent;
              border-radius: 1em;
              background-color: mix($blue,#fff,25%);

              background-color: transparent;
              border: 1px solid $blue;
              border: 0px solid $blue;
              color: $blue;
              font-size: 15px;
              font-weight: 100;

              @include media-breakpoint-up(sm) {
                font-size: 20px;
              }

              input:focus + label {
                background: #def;
              }
              input {
                width: 1px;
                height: 1px;
                display: inline-block;
                position: absolute;
                opacity: 0.01;
              }
              label {
                border-top: .06em solid #d9d9d9;
                border-top: 0em solid #d9d9d9;
                display:block;
                height: 2em;
                line-height: 2em;
                padding-left: 1em;
                cursor: pointer;
                position: relative;
                transition: $timing color ease-in-out;

                &:nth-child(2) {
                  margin-top: 2em;
                  border-top: .06em solid #d9d9d9;
                }

                @include media-breakpoint-up(md) {
                  padding-right: 3em;
                }
              }




              input:checked + label {
                display:block;
                border-top: none;
                position: absolute;
                top: 0;
                width: 100%;

                &:nth-child(2) {
                  margin-top: 0;
                  position: relative;
                }
              }

              &::after {
                content:"";
                position: absolute;
                right: 0.8em;
                top: 0.9em;
                border: .3em solid $blue;
                border-color: $blue transparent transparent transparent;
                transition: .4s all ease-in-out;
              }
              &.expanded {
                border: .06em solid $blue;
                background: #fff;
                border-radius: .25em;
                padding: 0;
                box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
                max-height:20em;

                label {
                  border-top: .06em solid #d9d9d9;

                  &:hover {
                    color:$blue;
                  }
                }
                input:checked + label {
                  color:$blue;
                }

                &::after {
                  transform: rotate(-180deg);
                  top:.55em;
                }
              }

              .filter_all {

                &.hide {
                  visibility: hidden;
                  height: 0px;
                  //display: none !important;
                }

                &.remove {
                  visibility: hidden !important;
                  height: 0px !important;
                }
              }
            }
          }
        }

        .referenzen_project_item {
          cursor: pointer;
          position: relative;
          overflow: hidden;
          border: 5px solid white;

          &.referenzen_project_item_text {
            cursor: default;
          }

          .referenzen_text_holder {
            background-color: #fff;

            .referenzen_text_holder_border {
              z-index: 2;
              text-align: right;
              position: absolute;
              top: 50px;
              right: 50px;
              width: calc(100% - 200px);
              height: calc(100% - 100px);
              border: 2px solid $blue;

            }

            .referenzen_text_holder_copy {
              position: relative;
              z-index: 3;
              text-align: right;
              margin: 80px 80px 80px 40px;
              padding: 10px;
              background-color: #fff;

              a:hover {
                text-decoration: underline;
              }
            }
          }

          .referenzen_thumb {
            z-index: 3;
            position: relative;
            overflow: hidden;

            &.portrait{
              height: 300px;

              .referenzen_thumb_image {
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
              }
            }

            @include media-breakpoint-up(md) {

            }

            .referenzen_thumb_image {
              width: 100%;
              //height: 100%;

              @include media-breakpoint-up(md) {

              }

              img {
                transition: .5s;
                width: 100%;
              }
            }

            img {
              transition: .5s;
              width: 100%;
            }
          }

          .referenzen_video {
            z-index: 4 !important;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            opacity: 0;
            z-index: 1;
            object-fit: cover;
            position: absolute;
            transition: .5s;
          }

          .referenzen_overlayer {
            z-index: 5;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgb(2,0,36);
            background-color: black;
            opacity: 0;
            transition: 0.5s;
          }

          .referenzen_title {
            z-index: 6;
            top: 0;
            left: 0;
            display: block;
            //text-align: center;
            will-change: transform;
            pointer-events: none;
            width: 100%;
            transition-duration: 200ms;
            transition-timing-function: ease-out;
            position: fixed;
            position: absolute;
            transform: translateY(-50%);

            text-align: center;
            bottom: auto;
            top: 0 !important;
            opacity: 0 !important;

            h2, h3 {
              color: white;
              max-width: 80%;
              margin-left:10%;
              text-align: center;
            }
          }

          .referenzen_seo_text {
            display: none;
          }

          &.isTouch {

            .referenzen_overlayer {
              background: linear-gradient(0deg, rgba(2,0,36,0.9) 0%, rgba(0,0,0,0) 60%);
              opacity: 1;
            }

            .referenzen_title {
              opacity: 1 !important;
              top: auto !important;
              bottom: -10px;

              h2, h3 {
                margin-left: 10px;
                text-align: left;
              }
            }
          }

          &.hover {

            .referenzen_video {
              opacity: 1;
            }
            .referenzen_overlayer {
              opacity: .4;
            }
            .referenzen_title {
              opacity: 1 !important;
            }
            .refenezen_thumb {

              img {
                transform: scale(1.1);
              }
            }
          }
        }

        .gutter-sizer {
          width: 0%;
        }
        .grid-sizer,
        .grid-item {
          width: 100%;
        }
        .grid-item--text,
        .grid-item--width2 {
          width: 100%;
        }

        @include media-breakpoint-up(sm) {

          .grid-sizer,
          .grid-item {
            width: 50%;
          }
          .grid-item--text{
            width: 100%;
          }
          .grid-item--width2 {
            width: 50%;
          }
        }
        @include media-breakpoint-up(md) {

          .grid-sizer,
          .grid-item {
            width: 50%;
          }
          .grid-item--text,
          .grid-item--width2 {
            width: 50%;
          }
        }
        @include media-breakpoint-up(lg) {

          .grid-sizer,
          .grid-item {
            width: 25%;
          }
          .grid-item--text,
          .grid-item--width2 {
            width: 50%;
          }
        }
        @include media-breakpoint-up(xl) {

          .grid-sizer,
          .grid-item {
            width: 20%;
          }
          .grid-item--text,
          .grid-item--width2 {
            width: 40%;
          }
        }
      }
    }
  }

  .container {
    z-index: 10;
  }
}

