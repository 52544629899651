#kunden {
  z-index: 1;
  background-color: white;

  .container-fluid {
    will-change: transform;

    .row {

      .col {
        text-align: center;
        padding-right: 0;
        padding-left: 0;

        .kunden_logo_container {
          width: 100%;
          overflow: hidden;

          .kunden_logo_row {
            display: flex !important;
            flex-direction: row !important;
            justify-content: center !important;
            width: 180%;
            margin-left: -40%;
            margin-bottom: 50px;

            @include media-breakpoint-up(sm) {
              width: 160%;
              margin-left: -30%;
            }

            @include media-breakpoint-up(md) {
              width: 110%;
              margin-left: -5%;
            }

            .kunden_logo {
              width: 77px;
              width: 15%;
              margin: 0 13px;
              //border: 1px solid red;

              /*@include media-breakpoint-up(sm) {
                width: 96px;
                margin: 0 16px;
              }
              @include media-breakpoint-up(md) {
                width: 120px;
                margin: 0 20px;
              }
              @include media-breakpoint-up(lg) {
                width: 150px;
                margin: 0 25px;
              }
              @include media-breakpoint-up(xl) {
                width: 150px;
                margin: 0 25px;
              }*/

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}