#werbefilm {
  z-index: 1;
  background-color: white;
  //padding-bottom: 0;

  .container-fluid {
    padding-right: 0;
    padding-left: 0;

    .row {
      --bs-gutter-x: 0;
      --bs-gutter-y: 0;

      .col{

        .werbefilm_image {
          position: relative;
          width: 150%;

          @include media-breakpoint-up(sm) {
            width: 100%;
          }

          img {
            width: 100%;
          }
        }

        .werbefilm_content {
          width: 100%;
          position: relative;
          background-image: url("./images/werbefilm/saubere_filme_werbefilm_image_mobile.jpg");
          background-size: cover;

          .werbefilm_text {
            width: 100%;
            height: 100%;

            .container-fluid {

              .row {

                .col {

                  .werbefilm_logo {
                    width: 100%;
                    width: 75%;
                    margin-left: auto;
                    margin-right: 0;

                    @include media-breakpoint-up(md) {
                      margin-left: 0;
                      margin-right: auto;
                    }

                    img, svg {
                      width: 100%;
                    }
                  }

                  p {
                    color: white;
                    margin-top: 40px;
                    margin-bottom: 40px;

                    @include media-breakpoint-up(sm) {
                      margin-top: 50px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}