//#green,
#werte {
  z-index: 1;
  background-color: white;
  overflow-x: hidden;



  .container {
    position: relative;
    z-index: 2;

    .row {

      .werte_bg_bubble {
        z-index: 1;
        position: absolute;
        bottom: -20px;
        right: 0px;
        width: 20%;
        min-width: 200px;
        opacity: .03;
        overflow-x: hidden;

        img, svg {
          width: 100%;
        }

        @include media-breakpoint-up(sm) {
          bottom: -30px;
        }
        @include media-breakpoint-up(md) {
          bottom: -40px;
        }
        @include media-breakpoint-up(lg) {
          bottom: -50px;
        }
        @include media-breakpoint-up(xl) {
          bottom: -60px;
        }
      }

      .col {

        .werte_caroussel {

          .slide {
            width: 100%;
            min-width: 100%;

            h2 {
              color: $magenta;
            }
          }
        }
      }

      .col_werte {
        z-index: 2;
        position: relative;
        border: 2px solid $blue !important;
        border: 2px solid $magenta !important;
        padding: 8.3%;
        padding: 6%;

        h1 {
          position: absolute;
          top: -60px;
          //right: -25px;
          padding: 100px;
          left: -8.3%;
          left: -30px;
          padding: 8.3%;
          padding: 6.3%;
          padding-bottom: 0;
          //padding-top: 30px;
          height: auto;
          margin-bottom: 0px !important;
          background-color: white;
          color: $magenta;
          margin-left: -0px !important;
        }

        @include media-breakpoint-up(sm) {

          h1 {
            left: -50px;
            top: -75px;
          }
        }
        @include media-breakpoint-up(md) {

          h1 {
            top: -90px;
          }
        }
        @include media-breakpoint-up(lg) {

          h1 {
            top: -120px;
          }
        }
        @include media-breakpoint-up(xl) {

          h1 {
            top: -140px;
          }
        }
      }
    }
  }


  // buttons
  .flickity-button {
    color: $magenta;
    background: white;
  }
  .flickity-prev-next-button.previous {
    left: -50px;

    @include media-breakpoint-up(sm) {
      left: -60px;
    }
    @include media-breakpoint-up(md) {
      left: -50px;
    }
    @include media-breakpoint-up(lg) {
      left: -60px;
    }
    @include media-breakpoint-up(xl) {
      left: -65px;
    }
    @include media-breakpoint-up(xxl) {
      left: -75px;
    }
  }
  .flickity-prev-next-button.next {
    right: -50px;

    @include media-breakpoint-up(sm) {
      right: -60px;
    }
    @include media-breakpoint-up(md) {
      right: -50px;
    }
    @include media-breakpoint-up(lg) {
      right: -60px;
    }
    @include media-breakpoint-up(xl) {
      right: -65px;
    }
    @include media-breakpoint-up(xxl) {
      right: -75px;
    }
  }
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px transparent;
  }

  // dots
  .flickity-page-dot.is-selected {
    background-color: $magenta;
  }
  .flickity-page-dot:focus {
    outline: none;
    box-shadow: 0 0 0 5px transparent;
  }
  .flickity-page-dots {
    margin-bottom: 10px;
  }
}