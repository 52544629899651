#footer {
  z-index: 1;
  background-color: white;
  padding-bottom: 0;
  //padding-top: 0;

  .container-fluid,
  .container{

    .row {
      margin-right: 0;
      margin-left: 0;

      .col {
        padding-right: 0;
        padding-left: 0;
        padding-left: 35px;
        //border: 1px solid red !important;

        @include media-breakpoint-up(md) {
          padding-left: 0;
        }

        .footer_logo {
          width: 130px;
          padding-top: 50px;

          img, svg {
            width: 100%;
          }
        }

        .footer_text {
          padding:  10px 0;

          a {
            padding-bottom: 0;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .footer_icons {
          padding: 10px 0 100px 0;

          .footer_icon {
            width: 40px;
            display: inline-block;
            transition: .5s;
            margin: 0 2px;

            img, svg {
              width: 100%;
            }

            &:hover {
              transform: scale(1.1);

              .socialmedia_icon_blue {
                fill: $yellow;
              }
            }
          }
        }

        .footer_links {
          padding-bottom: 20px;

          a {
            cursor: pointer;
            margin-right: 10px;
          }
        }

        .footer_copyright {
          padding: 0;

          p{
            padding-bottom: 10px;
          }
        }

        .footer_image {
          height: 100%;
          background-image: url("./images/footer/saubere_filme_footer_foto.jpg");
          //background-image: url("src/images/footer/footer-image.jpg");
          background-size:cover;
          background-position: center center;
        }
      }
    }
  }
}