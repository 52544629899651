#fotos {
  z-index: 1;
  background-color: white;
  //padding-top: 0px;

  .container-fluid {
    padding-right: 0;
    padding-left: 0;overflow-x: hidden;

    .row {
      --bs-gutter-x: 0;

      .col {

        .slick_caroussel_foto {
          height: 300px;
          width: auto;

          .slide{

            .slide_foto {
              height: 300px;

              img {
                height: 100%;
              }
            }
          }

          @include media-breakpoint-up(sm) {
            height: 310px;

            .slide{

              .slide_foto {
                height: 310px;
              }
            }
          }
          @include media-breakpoint-up(md) {
            height: 380px;

            .slide{

              .slide_foto {
                height: 380px;
              }
            }
          }
          @include media-breakpoint-up(lg) {
            height: 480px;

            .slide{

              .slide_foto {
                height: 480px;
              }
            }
          }
          @include media-breakpoint-up(xl) {
            height: 600px;

            .slide{

              .slide_foto {
                height: 600px;
              }
            }
          }
        }
      }
    }
  }


  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px transparent;
  }
}