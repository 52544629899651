//! import google fonts
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@font-face {
  font-family: "Gilroy-Regular";
  src: url('./fonts/GilroyRegular/GilroyRegular.woff2') format('woff2'), url('./fonts/GilroyRegular/GilroyRegular.woff') format('woff');
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url('./fonts/GilroySemiBold/GilroySemiBold.woff2') format('woff2'), url('./fonts/GilroySemiBold/GilroySemiBold.woff') format('woff');
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url('./fonts/GilroyMedium/GilroyMedium.woff2') format('woff2'), url('./fonts/GilroyMedium/GilroyMedium.woff') format('woff');
}
@font-face {
  font-family: "Gilroy-Light";
  src: url('./fonts/GilroyLight/GilroyLight.woff2') format('woff2'), url('./fonts/GilroyLight/GilroyLight.woff') format('woff');
}
@font-face {
  font-family: "StayDreaming";
  src: url('./fonts/StayDreaming/StayDreaming.woff2') format('woff2'), url('./fonts/StayDreaming/StayDreaming.woff') format('woff');
}

//! set font variables
$font-heading-main: StayDreaming, sans-serif;
//$font-heading-main: 'Gilroy-Regular', sans-serif;
$font-heading: 'Gilroy-Regular', sans-serif;
$font-body: sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 1.6rem;
  color: $black;
}

h1 {
  font-family: $font-heading-main;
  color: $blue;
  font-size: 52px;
  position: relative;
  //overflow: hidden;
  padding: 0px 10px;
  margin-left: -0px !important;
  margin-bottom: 7px !important;


  @include media-breakpoint-up(sm) {
    font-size: 52px;
    margin-left: -10px !important;
    margin-bottom: 8px !important;
  }
  @include media-breakpoint-up(md) {
    font-size: 64px;
    margin-left: -50px !important;
    margin-bottom: 10px !important;
  }
  @include media-breakpoint-up(lg) {
    font-size: 80px;
    margin-bottom: 13px !important;
  }
  @include media-breakpoint-up(xl) {
    font-size: 100px;
    margin-left: -80px !important;
    margin-bottom: 16px !important;
  }

  &.big {
    font-size: 78px;
    margin-bottom: 0px !important;

    @include media-breakpoint-up(sm) {
      font-size: 78px;
    }
    @include media-breakpoint-up(md) {
      font-size: 96px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 120px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 150px;
    }
  }


  &.werte {
    font-size: 40px;


    @include media-breakpoint-up(sm) {
      font-size: 52px;
    }
    @include media-breakpoint-up(md) {
      font-size: 64px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 80px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 100px;
    }
  }
}

h2 {
  font-family: "Gilroy-Regular", serif;
  font-size: 23px;
  line-height: 23px;
  color: $dark_grey;
  margin: 0;
  padding-bottom: 15px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 18px;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 23px;
    line-height: 23px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 28px;
    line-height: 28px;
  }

  &.leistungen{
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 0px;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 22px;
    }
    @include media-breakpoint-up(md) {
      font-size: 22px;
      line-height: 26px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 28px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &.team{
    padding-bottom: 5px;
  }

  &.referenzen {
    padding-bottom: 5px;
    font-size: 23px;
    line-height: 23px;


    @include media-breakpoint-up(sm) {
      font-size: 18px;
      line-height: 18px;
    }
    @include media-breakpoint-up(md) {
      font-size: 18px;
      line-height: 18px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 18px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

h3 {
  font-family: "Gilroy-Regular", serif;
  color: white;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  padding-bottom: 15px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 19px;
    line-height: 19px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 24px;
    line-height: 24px;
  }



  &.team{
    font-size: 18px;
    line-height: 18px;
    padding-bottom: 15px;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
      line-height: 14px;
    }
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 14px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 18px;
      line-height: 18px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 21px;
      line-height: 21px;
    }
  }


  &.referenzen {
    padding-bottom: 0px;


    line-height: 18px;
    padding-bottom: 15px;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
      line-height: 14px;
    }
    @include media-breakpoint-up(md) {
      font-size: 14px;
      line-height: 14px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 14px;
      line-height: 14px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

h4 {

}



h2_ {
  font-family: $font-heading, serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 30px;

  @include media-breakpoint-up(sm) {
    font-size: 20px;
    line-height: 26px;
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 26px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 24px;
    line-height: 32px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 30px;
    line-height: 40px;
  }

  &.werte {
    //color: $blue;
    padding-bottom: 15px;
  }
}

h3_ {
  font-family: "Gilroy-Medium", serif;
  font-family: "Gilroy-Regular", serif;
  color: $dark_grey;
  margin: 0;
  padding-bottom: 15px;
  font-size: 18px;
  line-height: 22px;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 22px;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
    line-height: 22px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
    line-height: 25px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 22px;
    line-height: 28px;
  }

  &.team {
    font-size: 22px;
    line-height: 22px;
    padding-bottom: 5px;
  }

  &.referenzen {
    color: white;
    max-width: 80%;

    padding-bottom: 5px;
  }
}

h4_ {
  font-family: "Gilroy-Regular", serif;
  font-size: 14px;
  line-height: 18px;
  color: white;
  margin: 0;
  padding-bottom: 15px;


  @include media-breakpoint-up(sm) {
    font-size: 14px;
    line-height: 18px;
  }
  @include media-breakpoint-up(md) {
    font-size: 14px;
    line-height: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 19px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
    line-height: 24px;
  }

  &.referenzen {
    padding-bottom: 0px;
    max-width: 80%;
  }
  &.team {
    padding-bottom: 0px;
    padding-bottom: 15px;
  }
}


p, a, li{
  font-family: "Gilroy-Light";
  font-family: "Gilroy-Regular";
  font-size: 15px;
  line-height: 22px;
  font-weight: 100;
  color: $dark_grey;
  margin: 0;
  padding-bottom: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 20px;
  }
  @include media-breakpoint-up(md) {
    font-size: 15px;
    line-height: 22px;
    padding-bottom: 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 24px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 30px;
  }


  &.language {
    font-family: "Gilroy-Regular";
    display: inline;
    padding: 0px;
    color: white;
    line-height: 20px;
  }
  &.active {
    font-family: "Gilroy-SemiBold";
  }
  &.small {
    font-size: 15px;
    line-height: 19px;
  }
  &.medium {
    font-size: 22px;
    line-height: 19px;
  }
  &.big {
    font-size: 28px;
    line-height: 28px;
    padding-bottom: 15px;
  }
  &.font_regular {
    font-family: "Gilroy-Regular";
  }
  &.font_bold {
    font-family: "Gilroy-SemiBold";
  }
  &.referenzen {
    color: white;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 0px;
  }
  &.leistungen{
    text-decoration: underline;
  }
  &.team {
    font-family: "Gilroy-Regular";
    color: white;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 0px;
  }
  &.contact {
    padding-bottom: 5px;
  }
  &.footer {

    &:hover {
      text-decoration: underline;
    }
  }
  &.padding_bottom_10 {
    padding-bottom: 10px;
  }
  &.nowrap {
    white-space: nowrap
  }
}

a {

  &:hover {
    color: #4ea0e1;
  }

  &.contact {

    &:hover {
      //color: white;
      text-decoration: underline;
    }
  }
}

ul {
  list-style:disc !important;
  padding-left: 20px !important;
  color: red;
}
li {
  padding-bottom: 10px;
  padding-left: 5px;
}


.button_holder {
  text-align: center;
  //padding-bottom: 30px;
  padding-top: 20px;
}

a.round_button {
  font-family: 'Gilroy-Light', serif;
  font-family: 'Gilroy-Regular', serif;
  position: relative;
  display: inline-block;
  border: 1px solid $blue;
  background-color: transparent;
  border-radius: 25px;
  color: $blue;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  padding: 14px 40px;
  transition: background-color .3s, color .3s;
  overflow: hidden;
  margin-bottom: 0px;


  @include media-breakpoint-up(sm) {
    font-size: 15px;
    line-height: 15px;
  }
  @include media-breakpoint-up(md) {
    font-size: 15px;
    line-height: 15px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 18px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 20px;
    line-height: 20px;
  }

  &:hover {
    color: white;
    background-color: $blue;
  }
  &.small {
    font-size: 14px;
    line-height: 14px;
    padding: 7px 20px;
  }
  &.white {
    border: 1px solid white;
    color: white;

    &:hover {
      color: $blue;
      background-color: white;
    }
  }
}

.text-right {
  text-align: right;
}

.font-regular {
  font-family: "Gilroy-Regular";
}
.font_medium {
  font-family: "Gilroy-Medium";
}

.font_bold {
   font-family: "Gilroy-SemiBold";
 }

strong {
  font-family: "Gilroy-Medium";
}