#about {
  z-index: 1;
  background-color: white;

  .about_blue_bg_bubble {
  }

  .container {
    will-change: transform;

    .row {
      display: flex;
      align-items: center;

      .col {

        ul {


          @include media-breakpoint-up(xl) {
            text-align: right;
          }

          li {
            display: inline-block;
            margin: 0;
            padding: 10px 0 10px 30px;
            list-style: none;
            background-image: url("./images/svg/bg_blue_bubbles.svg");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 20px;
            //border: 1px solid red;
          }
        }

        p {
          display: inline-block;

          &.text-right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          img {
            display: inline-block;
            margin-right: 10px;
            width: 20px;
          }
        }
      }
    }
  }
}