//? set your brand colors

$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #4ea0e1;
$magenta: #ef869b;
$yellow: #fce678;
$green: #96ca80;
$dark_grey: #000000; // 535353
$middle_grey: #777777;


.color-blue {
  color: $blue;
}
.color-magenta {
  color: $magenta;
}
.color-yellow {
  color: $yellow;
}
.color-green {
  color: $green;
}
.color-darkgrey {
  color: $dark_grey;
}
.color-middle_grey {
  color: $middle_grey;
}
.color-white {
  color: white;
}

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: "~slick-carousel/slick/";
