#team {
  z-index: 1;
  background-color: white;
  //padding-top: 0px;

  .team_blue_bg_bubble {
    top: 50px;
    left: -50px;

    img, svg {
      transform: translateX(0);
    }
  }

  .container-fluid{
    will-change: transform;
    position: relative;
    z-index: 2;
    padding-right: 0;
    padding-left: 0;

    .row {
      padding-right: 0;
      padding-left: 0;
      --bs-gutter-x: 0;
      --bs-gutter-y: 0;

      .col_team_foto {
        padding-bottom: 0;
      }

      .col {
        position: relative;
        display: flex;
        align-items: center;

        .team_intro_text_holder {
          position: relative;
          top: 0px;
          //border: 1px solid red;

          a {
            color: $blue;

            &:hover {
              text-decoration: underline;
            }
          }

          @include media-breakpoint-up(sm) {

          }
          @include media-breakpoint-up(md) {

          }
          @include media-breakpoint-up(lg) {
            position: absolute;
            top: -150px;
          }
          @include media-breakpoint-up(xl) {
            top: -170px;
          }
          @include media-breakpoint-up(xxl) {
            top: -180px;
          }
        }

        a {
          font-family: "Gilroy-Regular";
          //color: $blue;
          display: block;

        }

        .content_image_mask{
          display: none;

          @include media-breakpoint-up(md) {
           display: block;
          }
        }

        .team_foto_mask {
          position: relative;
          width: 100%;
          overflow: hidden;
          padding: 10px;

          .team_foto_text_holder {
            //border: 1px solid red;
            background-color: #fff;

            .team_foto_text_holder_border {
              z-index: 2;
              text-align: right;
              position: absolute;
              top: 50px;
              right: 50px;
              width: calc(100% - 200px);
              height: calc(100% - 100px);
              border: 2px solid $blue;

              a {
                color: $blue;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .team_foto_text_holder_copy {
              position: relative;
              z-index: 3;
              text-align: right;
              margin: 80px 80px 80px 40px;
              padding: 10px;
              background-color: #fff;

              a:hover {
                text-decoration: underline;
              }
            }
          }

          .team_foto_container {
            width: 100%;
            overflow: hidden;

            .team_foto_image,
            .team_foto_image_mobile{
              width: 100%;
              transition: .5s;
              transform-origin: center top;
              transform-origin: center center;

              img {
                width: 100%;
              }
            }
            .team_foto_image {
              display: none;
            }
            .team_foto_abdecker {
              position: absolute;
              top: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              background-color: black;
              background: linear-gradient(0deg, rgba(2,0,36,0.9) 0%, rgba(0,0,0,0) 100%);
              opacity: 0;
              transition: .5s;
            }
            .team_foto_text {
              text-align: center;
              position: absolute;
              width: 100%;
              padding-top: 40px;
              transition: 0.5s;
              opacity: 0;
              bottom: 10%;
              left: 50%;
              transform: translate(-50%, 10px);

              h2, h3 {
                max-width: 80%;
                margin-left:10%;
              }
              p {
                max-width: 80%;
                margin-left:10%;
              }
              a {
                //margin-top: 10px;
              }
            }

            &.show{

             .team_foto_image {
                transform: scale(1.1);
              }
              .team_foto_abdecker {
                opacity: .35;
              }
              .team_foto_text {
                opacity: 1;
                transform: translate(-50%, 0px);
              }
            }

            &.isTouch {

              .team_foto_abdecker {
                background: linear-gradient(0deg, rgba(2,0,36,0.7) 0%, rgba(0,0,0,0) 60%);
                opacity: 1;
              }

              .team_foto_text {
                opacity: 1;
              }
            }

            @include media-breakpoint-up(lg) {

              .team_foto_image {
                display: block;
              }

              .team_foto_image_mobile {
                display: none;
              }
            }
          }
        }
      }

      .col_align_bottom {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
      .col_align_top {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }

  .container_text {
    z-index: 100;

    @include media-breakpoint-up(md) {

    }
  }

  .container_fotos {
    z-index: 99;
    //margin-top: 82px;
    margin-bottom: 82px;
    margin-bottom: 40px;

    @include media-breakpoint-up(sm) {
      //margin-top: 102px;
      margin-bottom: 50px;
    }
    @include media-breakpoint-up(md) {
      //margin-top: 128px;
      margin-bottom: 64px;
    }
    @include media-breakpoint-up(lg) {
      //margin-top: 160px;
      margin-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
      //margin-top: 200px;
      margin-bottom: 100px;
    }
  }
}