#contact {
  z-index: 1;
  //background-color: $blue;

  .contact_blue_bg_bubble {
    z-index: 1;
    top: 50px;
    left: -50px;

    img, svg {
      transform: translateX(0);
    }
  }

  .container,
  .container-fluid {
    z-index: 2;
    position: relative;
    .row {
      display: flex;
      align-items: center;

      .col {
        position: relative;

        .contact_copy {
          margin-bottom: 50px;
          margin-bottom: 20px;
        }
        .contact_copy_02 {
          margin-bottom: 50px;
        }
        .contact_button {
          display: block;
          margin-bottom: 100px;
        }

        .container {
          padding-top: 50px;
          padding-right: 0;
          padding-left: 0;

          .row {

            .col {
              padding: 0;
              padding-bottom: 20px;

              @include media-breakpoint-up(md) {
                padding-bottom: 50px;
              }

              .contact_item {
                width: 100%;
                display: flex;
                align-items: flex-start;

                @include media-breakpoint-up(md) {
                  height: 100px;
                }
                .contact_item_icon {
                  width: 50px;

                  .socialmedia_icon_white {
                    transition: .5s;
                  }

                  img, svg {
                    width: 50px;
                  }
                }
                .contact_item_text {
                  margin: 10px;
                }

                &.contact_item_button {
                  cursor: pointer;
                  align-items: center;
                }

                &:hover {

                  .contact_item_icon {

                    .socialmedia_icon_white {
                      fill: $yellow;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

